import React, { useState, useEffect } from "react";
import { Criteo } from "~/components/Criteo/Criteo";
import SEO from "~/components/seo";
import { DeriveHeaderClasses } from "~/components/Navigation";
import { ResetForm } from "~/components/AccountPages";
import { resetPassword } from "~/helpers/requests/customer-requests";
import Cookies from "js-cookie";
import { css } from "styled-components";
const ResetPage = ({ location }) => {
  const [authErrorMessage, setAuthErrorMessage] = useState("");
  const [customerID, setCustomerID] = useState("");
  const [accessToken, setAccessToken] = useState("");
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setCustomerID(btoa(`gid://shopify/Customer/${params.get("id")}`));
    setAccessToken(params.get("token"));
  }, [location.search]);
  const submitForm = (values) => {
    resetPassword(customerID, accessToken, values.password).then((res) => {
      const { customerReset } = res.data.data;

      if (!customerReset.customerUserErrors.length) {
        const { accessToken, expiresAt } = customerReset.customerAccessToken;

        const expiryDate = new Date(expiresAt);
        Cookies.set("KEPT_SESS", accessToken, { expires: expiryDate });
        window.location.href = "/shop";
      } else {
        setAuthErrorMessage(customerReset.customerUserErrors[0]);
      }
    });
  };
  return (
    <div
      css={css`
        min-height: calc(100vh - 123px - 150px);
        h1 {
          text-align: center;
          margin: 1rem auto 2rem;
        }
      `}
    >
      <Criteo />
      <SEO title="Reset Password" />
      <DeriveHeaderClasses />
      <div className="container text-center">
        <div className="row no-gutters pt-50 collection-header">
          <h1 className="ma">Reset Password</h1>
        </div>
      </div>
      <div className="error">{authErrorMessage}</div>
      <ResetForm submitForm={submitForm} authErrorMessage={authErrorMessage} />
    </div>
  );
};

export default ResetPage;
